<template>
  <div class="container">
    <h2>{{ msg }}</h2>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: {
    msg: String,
  },
};
</script>

<style>
h2 {
  text-align: center;
}
</style>
