<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="card shadow mb-3">
          <div class="card-header bg-secondary text-center text-light fs-3">
            <i class="bi-calculator d-inline"> Paso 1 </i>
            <span> | </span>
            <p class="d-inline">Calculador Ingreso Pasivo en ADA</p>
          </div>
          <div class="card-body">
            <form class="form-group">
              <div class="mb-4">
                <label for="passiveCLP" class="form-label"
                  >Ingreso pasivo mensual esperado en Pesos Chilenos</label
                >
                <div class="row d-flex">
                  <div class="col-10 col-xl-6 mb-2">
                    <div class="input-group">
                      <div class="input-group-text">CLP</div>
                      <input
                        v-model="passiveCLP"
                        type="tel"
                        v-cleave="options"
                        class="form-control form-control-lg"
                        name="passiveCLP"
                        id="passiveCLP"
                      />
                    </div>
                    <div class="text-danger" v-if="validClp">
                      Completar ingreso passivo
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-1">
                <label for="adaUSD" class="form-label"
                  >Valor del ADA en USD en su horizonte de inversión esperado y
                  el % de retorno anual del "Stake Pool" (entre 5% y 6%).
                </label>
                <div class="row d-flex">
                  <div class="col-6 col-xl-4">
                    <div class="input-group mb-1">
                      <div class="input-group-text">USD</div>
                      <input
                        v-model="adaUSD"
                        v-cleave="options"
                        type="tel"
                        class="form-control form-control-lg"
                        name="adaUSD"
                        id="adaUSD"
                      />
                    </div>
                    <div class="text-danger" v-if="validAda">
                      Completar precio ADA
                    </div>
                  </div>

                  <div class="col-4 col-xl-2 mb-2">
                    <input
                      v-model="poolRate"
                      v-cleave="{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralDecimalScale: 1,
                        numeralDecimalMark: ',',
                        delimiter: '.',
                        prefix: '% ',
                        signBeforePrefix: true,
                      }"
                      type="tel"
                      class="form-control form-control-lg"
                      name="poolRate"
                      id="pool
                      Rate"
                    />
                    <div class="text-danger" v-if="validPrate">Interés</div>
                  </div>
                  <div class="col-md-4">
                    <button
                      type="button"
                      class="btn btn-success btn-lg"
                      v-on:click="calcAda"
                    >
                      Calcular
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer" v-show="show">
            <div class="alert alert-success mt-3" role="alert">
              <h4>
                Debes a esa fecha haber acumulado
                <strong>{{ adaSave }} ADA</strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card shadow mb-3">
          <div class="card-header bg-secondary text-center text-light fs-3">
            <i class="bi-wallet2 d-inline"> Paso 2 </i>
            <span> | </span>
            <p class="d-inline">Calculador Ahorro en ADA</p>
          </div>
          <div class="card-body">
            <div class="alert alert-secondary" role="alert" v-show="show">
              Aquí calcula ahorro mensual para acumular
              <strong>{{ adaSave }} ADA</strong> en base al interés compuesto
              del "Stake Pool".
            </div>
            <div
              class="alert alert-warning alert-dismissible fade show"
              role="alert"
              v-if="step1"
            >
              <strong>Primero el Paso 1</strong> Necesitamos saber cuanto ADA
              hay que ahorrar para el ingreso pasivo mensual esperado.
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
            <form class="form-group">
              <div class="mb-4">
                <label for="invCLP" class="form-label"
                  >Inversión inicial en Pesos Chilenos</label
                >
                <div class="row d-flex">
                  <div class="col-12 col-md-8 col-xl-5 mb-2">
                    <div class="input-group">
                      <div class="input-group-text">CLP</div>
                      <input
                        v-model="invCLP"
                        type="tel"
                        v-cleave="options"
                        class="form-control form-control-lg"
                        name="invCLP"
                        id="invCLP"
                      />
                    </div>
                    <div class="text-danger" v-if="validInvclp">
                      Ingresar invesión incicial en pesos Chilenos
                    </div>
                  </div>
                  <div class="col-12 col-md-8 col-xl-4 mb-2">
                    <div class="input-group">
                      <div class="input-group-text">ADA</div>
                      <input
                        v-model="invADA"
                        :disabled="true"
                        class="form-control form-control-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-1">
                <label for="saveADA" class="form-label"
                  >Ahorro mensual en ADA (el valor en CLP fluctuará en el
                  tiempo), ingresa años de ahorro e interés anual "Stake Pool"
                  que pagan cada 5 días.
                </label>
                <div class="row d-flex">
                  <div class="col-8 col-md-3 col-lg-4 col-xxl-3 mb-1">
                    <div class="input-group mb-1">
                      <div class="input-group-text">Años</div>
                      <input
                        v-model="years"
                        v-cleave="{
                          numeral: true,
                          numeralPositiveOnly: true,
                          numeralIntegerScale: 2,
                          numeralDecimalScale: 0,
                          delimiter: '',
                        }"
                        type="tel"
                        class="form-control form-control-lg"
                        name="saveADA"
                        id="saveADA"
                      />
                    </div>
                    <div class="text-danger" v-if="validYears">
                      Ingresar años de ahorro
                    </div>
                  </div>
                  <div class="col-4 col-md-2 col-lg-3 col-xxl-2 mb-1">
                    <input
                      v-model="rate"
                      v-cleave="{
                        numeral: true,
                        numeralPositiveOnly: true,
                        numeralDecimalScale: 1,
                        numeralDecimalMark: ',',
                        delimiter: '.',
                        prefix: '% ',
                        signBeforePrefix: true,
                      }"
                      type="tel"
                      class="form-control form-control-lg"
                      name="rate"
                      id="rate"
                    />
                  </div>
                  <div class="col-12 col-md-4 col-lg-6 col-xxl-4 mb-1">
                    <div class="input-group mb-1">
                      <div class="input-group-text">ADA</div>
                      <input
                        v-model="saveADA"
                        :disabled="true"
                        type="tel"
                        class="form-control form-control-lg"
                        name="saveADA"
                        id="saveADA"
                      />
                    </div>
                    <div class="text-danger" v-if="validRate">Interés</div>
                  </div>
                  <div class="col-6 col-md-3 col-lg-4 col-xxl-3 mb-1">
                    <button
                      type="button"
                      class="btn btn-success btn-lg"
                      v-on:click="calcSave"
                    >
                      Calcular
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer" v-show="result">
            <p>
              Para una inversión incial de
              <strong>{{ this.summary[2] }}</strong>
              pesos chilenos que hoy son equivalentes a
              <strong>{{ invADA }} ADA</strong>
              para lograr tu objetivo de tener un ingreso pasivo mensual en
              pesos chilenos de
              <strong>{{ this.summary[0] }}</strong>
              para un dolar de
              <strong>$ {{ this.summary[1] }}</strong>
              tu ahorro mensual debe ser de
              <strong>{{ saveADA }} ADA</strong>
              durante <strong>{{ this.summary[3] }}</strong> años para un
              interés compuesto de <strong>{{ this.summary[4] }}</strong> anual
              que se compone cada 5 días y que el valor de ADA a esa fecha sería
              de <strong>USD {{ this.summary[5] }}</strong
              >.
            </p>
          </div>
          <div class="card-footer" v-show="ready">
            <div class="alert alert-secondary" role="alert">
              <p>No es necesario ahorrar para la inversion incial ingresada.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filterById } from "../utils/utils.js";

export default {
  name: "AdaCalc",
  props: ["ticker", "cdata", "usdclp"],
  data() {
    return {
      errors: [],
      show: false,
      step1: false,
      result: false,
      ready: false,

      passiveCLP: null,
      adaUSD: null,
      poolRate: null,

      validClp: false,
      validAda: false,
      validPrate: false,

      years: null,
      invCLP: null,
      invADA: null,
      rate: null,

      validInvclp: false,
      validYears: false,
      validRate: false,

      adaSave: null, // para el ingreso pasivo objetivo
      saveADA: null, // mensual para llegar al adaSave dado una inv incicial

      summary: [],

      options: {
        numeral: true,
        numericOnly: true,
        numeralPositiveOnly: true,
        numeralDecimalScale: 0,
        numeralDecimalMark: ",",
        delimiter: ".",
        stripLeadingZeroes: false,
        prefix: "$ ",
        signBeforePrefix: true,
      },
    };
  },
  methods: {
    filterById,
    calcAda(e) {
      let clp = NaN;
      let adausd = NaN;
      let rate = NaN;

      this.step1 = false;

      this.validClp = false;
      this.validAda = false;
      this.validPrate = false;

      if (this.passiveCLP !== null) {
        clp = parseFloat(this.passiveCLP.substring(1).replace(/\./g, ""));
      }
      if (this.adaUSD !== null) {
        adausd = parseFloat(this.adaUSD.substring(1));
      }

      if (this.poolRate !== null) {
        rate = parseFloat(this.poolRate.substring(1).replace(",", ".")) / 100;
      }

      if (clp && adausd && rate) {
        let calc = (clp * 12) / rate / adausd / this.usdclp;
        this.adaSave = Math.round(calc);
        this.show = true;
        return true;
      }

      this.errors = [];

      if (!clp) {
        this.errors.push("Falta Ingreso Pasivo espera en CLP");
        this.validClp = true;
      }
      if (!adausd) {
        this.errors.push("Falta ingresar valor futuro ADA en USD");
        this.validAda = true;
      }
      if (!rate) {
        this.errors.push("Falta ingresar Interes anual stake Pool");
        this.validPrate = true;
      }

      e.preventDefault();

      console.log(this.errors);
    },
    calcSave(e) {
      if (this.adaSave == null) {
        this.step1 = true;
        return;
      }

      this.summary = [];

      this.result = false;
      this.ready = false;

      let clp = NaN;
      let ratesave = NaN;
      let saveyears = NaN;

      this.validInvclp = false;
      this.validRate = false;
      this.validYears = false;

      if (this.invCLP !== null) {
        clp = parseFloat(this.invCLP.substring(1).replace(/\./g, "")); //inversion incial de CLP a USD
      }
      if (this.rate !== null) {
        ratesave = parseFloat(this.rate.substring(1).replace(",", ".")) / 100;
      }
      if (this.years !== null) {
        saveyears = this.years;
      }

      let Po = clp / this.usdclp;
      let adaPrice = filterById(this.cdata, this.ticker)[0].current_price;

      if (clp && ratesave && saveyears) {
        this.invADA = Po / adaPrice; //inv incial en ADA

        let Poc = Po * (1 + ratesave / 73) ** (73 * saveyears); //Inv incial int compuesto cada 5 dias a x anios
        Poc = Poc / adaPrice; //Pasamos inv inicial con int cmopuesto a ADAs

        let Cf = this.adaSave; // Valor final de la suma int compuesto debe ser la meta de ADAs
        let Cfp = Cf - Poc; // Restamos los ADA que acumulamos por Inv incial

        //Calculamos el aporte mensual si es que Cfp es mayor a 0
        // 73 debido a que el interes se compone cada 5 dias eso es 73 veces en el año
        if (Cfp > 0) {
          let Co =
            (Cfp * ratesave) /
            73 /
            (1 + ratesave / 73) /
            ((1 + ratesave / 73) ** (73 * this.years) - 1);
          this.saveADA = Math.round(Co) * 6;
          this.invADA = this.invADA.toFixed(2).toString().replace(".", ",");
          this.result = true;

          this.summary.push(
            this.passiveCLP,
            this.usdclp.toFixed(2).toString().replace(".", ","),
            this.invCLP,
            this.years,
            this.rate,
            this.adaUSD
          );

          return this.summary, this.result;
        } else {
          let Co = 0;
          this.invADA = this.invADA.toFixed(2).toString().replace(".", ",");
          this.saveADA = Co * 6;
          this.result = false;
          this.ready = true;
          return this.ready;
        }
      }

      this.errors = [];

      if (!clp) {
        this.errors.push("Falta inversión inicial");
        this.validInvclp = true;
      }
      if (!saveyears) {
        this.errors.push("Falta años de ahorro");
        this.validYears = true;
      }
      if (!ratesave) {
        this.errors.push("Falta ingresar Interes anual stake Pool");
        this.validRate = true;
      }

      e.preventDefault();

      console.log(this.errors);
    },
  },
};
</script>

<style>
input {
  font-size: xx-large;
  text-align: right;
}
</style>
