<template>
  <div class="container mt-4">
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      <h4 class="alert-heading fw-bold">Advertencia</h4>
      <p>
        Esto no es asesoría financiera es solo una opinión. Existe un riesgo
        asociado a las inversiones en acciones, cryptomonedas o cualquier otra
        cosa. Ninguna de la información presentada pretende ser la base para
        cualquier oferta, recomendación o tener alguna relación con los
        objetivos de inversion, financieros o necesidades en específico de cada
        persona por ende todo el contenido en esta web es meramente informativo
        - educacional.
      </p>
      <hr />
      <p class="mb-0">
        En las crypto monedas tu eres tu banco, nadie guarda tus claves de tu
        billetera digital, las pierdes o te las roban es tu responsabilidad.
      </p>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFinAdv",
};
</script>

<style></style>
