<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 mb-2">
        <div class="card shadow">
          <div class="card-body text-center">
            <h2>
              1 {{ filterById(cdata, ticker)[0].symbol }} = USD
              {{ formatNumber(filterById(cdata, ticker)[0].current_price) }}
            </h2>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-2">
        <div class="card shadow">
          <div class="card-body text-center">
            <h2>
              1 {{ filterById(cdata, ticker)[0].symbol }} = CLP
              {{
                formatNumber(
                  filterById(cdata, ticker)[0].current_price * usdclp
                )
              }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filterById, formatNumber } from "../utils/utils.js";

export default {
  name: "StockPrice",
  props: {
    ticker: String,
    cdata: null,
    usdclp: null,
  },
  methods: {
    filterById,
    formatNumber,
  },
};
</script>
