<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
        <div class="card">
          <img src="../assets/daedalus_logo.png" class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title">Billetera Digital Daedalus</h5>
            <p class="card-text">
              Una de las billeteras oficiales del proyecto de Cardano de código
              abierto. Aplicación instalable en el PC.
            </p>
            <a href="https://daedaluswallet.io/" class="btn btn-primary"
              >Sitio Oficial</a
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
        <div class="card">
          <img src="../assets/yoroi_logo.png" class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title">Billetera Digital Yoroi</h5>
            <p class="card-text">
              También oficial del proyecto Cardano pretende ser la de uso
              diario. App para el celular y el browser.
            </p>
            <a href="https://yoroi-wallet.com" class="btn btn-primary"
              >Sitio Oficial</a
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
        <div class="card">
          <img src="../assets/binance_logo.png" class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title">Exchange Binance</h5>
            <p class="card-text">
              Para compra y ventas de criptomonedas, es el mas grande del mundo.
              Fundado el 2005.
            </p>
            <a
              href="https://www.binance.com/en/register?ref=77717829"
              class="btn btn-primary"
              >Crea una cuenta aquí</a
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
        <div class="card">
          <img src="../assets/coinbase_logo.png" class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title">Exchange Coinbase</h5>
            <p class="card-text">
              También uno de los mas grandes del mundo posee una interfaz
              bastante amigable.
            </p>
            <a
              href="https://www.coinbase.com/join/sobene_l"
              class="btn btn-primary"
              >Crea una cuenta aquí</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Links",
};
</script>

<style></style>
