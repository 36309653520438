import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cryptoData: Array,
    usdToClp: Number,
  },
  mutations: {
    //Siempre sincronicas
    setCryptoData(state, payload) {
      state.cryptoData = payload;
    },
    setUsdToClp(state, payload) {
      state.usdToClp = payload;
    },
  },
  actions: {
    //Aqui van las async
    async setCryptoData(state) {
      const url =
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd";
      await fetch(url)
        .then((res) => res.json())
        .then((data) => state.commit("setCryptoData", data))
        .catch((err) => {
          console.log("API call error:", err.message);
        });
    },
    async setUsdToClp(state) {
      const url =
        "https://free.currconv.com/api/v7/convert?q=USD_CLP&compact=ultra&apiKey=f66da3190630dd8ed086";
      await fetch(url)
        .then((res) => res.json())
        .then((value) => state.commit("setUsdToClp", value.USD_CLP))
        .catch((err) => console.log("API call error:", err.message));
    },
  },
  modules: {},
  getters: {
    getCryptoData: (state) => state.cryptoData,
    getUsdToClp: (state) => state.usdToClp,
  },
});
