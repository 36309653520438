import Vue from "vue";
import App from "./App.vue";
import store from "./store";

import bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-V0YWSQ4D5E" },
});

//Cleave para formatear numeros, cleave.js---------------//
import Cleave from "cleave.js";
Vue.directive("cleave", {
  inserted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {});
  },
  update: (el) => {
    const event = new Event("input", { bubbles: true });
    setTimeout(function () {
      el.value = el.cleave.properties.result;
      el.dispatchEvent(event);
    }, 100);
  },
});

Vue.config.productionTip = true;

//Error handler
Vue.config.errorHandler = function (err, vm, info) {
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
};

new Vue({
  store,
  bootstrap,
  VueGtag,
  render: (h) => h(App),
}).$mount("#app");
