<template>
  <footer class="footer mt-auto py-3 bg-light">
    <div class="row d-flex d-flex justify-content-between">
      <div class="col-12 col-md-6">
        <span class="text-muted">{{ msg }}</span>
      </div>
      <div class="col-12 col-md-6 text-end">
        <span>
          <a
            class="text-muted"
            href="mailto: info@adacardano.cl"
            style="text-decoration: none"
          >
            {{ email }}
          </a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
    email: String,
  },
};
</script>

<style></style>
