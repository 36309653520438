function filterById(items, param) {
  return items.filter((item) => {
    return item.id == param;
  });
}

function formatNumber(num) {
  num = parseFloat(num).toFixed(2);
  return num.toString().replace(".", ",");
}

export { filterById, formatNumber };
