<template>
  <div class="container">
    <div class="card shadow">
      <div class="card-img-top text-center mt-3">
        <img src="../assets/logo.png" />
      </div>
      <div class="card-body">
        ADA Cardano es el primer blockchain revisado por pares del mundo 100%
        descentralizado y operado por la comunidad de "Stake Pools". La
        fundación sin fines de lucro responsable de Cardano reunió una red de
        académicos y científicos de varias universidades, incluida la
        Universidad de Edimburgo y el Instituto de Tecnología de Tokio, para
        revisar sus protocolos antes de que se publicaran. Es una criptomoneda
        de tercera generación y una plataforma de contrato inteligente que
        pretende mejorar los problemas de escala de bitcoin, moneda de primera
        generación, y ethereum, que es la segunda generación.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefineCardano",
  props: {
    msg: String,
  },
};
</script>
