<template>
  <div class="container">
    <div class="accordion" id="faq">
      <div
        class="accordion-item"
        v-for="(texto, index) in textos"
        :key="texto.title"
      >
        <h2 class="accordion-header" :id="'heading-' + index">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse-' + index"
            aria-expanded="true"
            :aria-controls="'collapse-' + index"
          >
            <h5>{{ texto.title }}</h5>
          </button>
        </h2>
        <div
          :id="'collapse-' + index"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading-' + index"
          data-bs-parent="#faq"
        >
          <div class="accordion-body">
            <p>
              <strong>{{ texto.quote }}</strong
              >{{ texto.body }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textos from "../assets/textos.json";

export default {
  name: "Faq",
  data: () => {
    return {
      textos: textos.faq,
    };
  },
};
</script>

<style></style>
