<template>
  <div class="container">
    <div class="row">
      <NotFinAdv />
    </div>
    <div class="row mt-4">
      <DefineCardano />
    </div>
    <div class="row mt-4">
      <Message msg="Mi plan de Jubilación con ADA Cardano" />
    </div>
    <div class="row mt-4">
      <StockData
        ticker="cardano"
        v-bind:cdata="cryptoData"
        v-bind:usdclp="usdToClp"
      />
    </div>
    <div class="row mt-4">
      <AdaCalc
        ticker="cardano"
        v-bind:cdata="cryptoData"
        v-bind:usdclp="usdToClp"
      />
    </div>
    <div class="row mt-4">
      <Faq />
    </div>
    <div class="row mt-4">
      <Links />
    </div>
    <div class="row mt-4">
      <Footer
        msg="Copyright  AdaCardano.cl - 2021"
        email="info@adacardano.cl"
      />
    </div>
  </div>
</template>

<script>
import NotFinAdv from "./components/NotFinAdv.vue";
import DefineCardano from "./components/DefineCardano.vue";
import Message from "./components/Message.vue";
import StockData from "./components/StockData.vue";
import AdaCalc from "./components/AdaCalc.vue";
import Footer from "./components/Footer.vue";
import Faq from "./components/Faq.vue";
import Links from "./components/Links.vue";

export default {
  name: "App",
  components: {
    NotFinAdv,
    DefineCardano,
    Message,
    StockData,
    AdaCalc,
    Footer,
    Faq,
    Links,
  },
  data() {
    return {
      ticker: String,
      cdata: Array,
      usdclp: Number,
    };
  },
  mounted() {
    this.$store.dispatch("setCryptoData");
    this.$store.dispatch("setUsdToClp");
  },
  computed: {
    cryptoData() {
      return this.$store.getters.getCryptoData;
    },
    usdToClp() {
      return this.$store.getters.getUsdToClp;
    },
  },
};
</script>

<style>
.container {
  border: 0px;
  border-style: dotted;
  border-color: red;
}

.row {
  border: 0px;
  border-style: dotted;
  border-color: royalblue;
}

.col {
  border: 0px;
  border-style: dotted;
  border-color: green;
}
</style>
